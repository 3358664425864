@@ -1,76 +0,0 @@
<template>
  <v-card class="pa-8">
    <h1 class="text-center">แก้ไขข้อมูลราคา</h1>
    <v-row>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          v-model="form.petType"
          label="ประเภทบริการ"
          outlined
          dense
          hide-details="auto"
          disabled
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          v-model="form.grooming_price"
          label="Grooming Price"
          outlined
          dense
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          v-model="form.short_hair_price"
          label="Short Hair Price"
          outlined
          dense
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          v-model="form.long_hair_price"
          label="Long Hair Price"
          outlined
          dense
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          v-model="form.weight_start"
          label="น้ำหนักเริ่มต้น"
          outlined
          dense
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          v-model="form.weight_end"
          label="น้ำหนักสิ้นสุด"
          outlined
          dense
          hide-details="auto"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="text-end">
        <v-btn @click="goToBack()">back</v-btn> &emsp;
        <v-btn color="primary" @click="submit()">save</v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { Encode } from "@/services";
import { Decode } from "@/services";
export default {
  data() {
    return {
      form: {},
    };
  },
  created() {
    this.getOne();
  },
  // async mounted() {
  //   const checkuser = JSON.parse(
  //     Decode.decode(localStorage.getItem("DDLotAdmin"))
  //   );
  //   this.form = checkuser.user;
  // },
  methods: {
    async getOne() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/priceLists/${this.$router.currentRoute.query.id}`
      );
      this.form = response.data.data;

      console.log("this.form", this.form);
      for (let i in this.itemsData) {
        this.itemsData[i].index = parseInt(i) + 1;
      }
    },
    goToBack() {
      this.$router.go(-1);
    },
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage1 = URL.createObjectURL(element);
        };
      }
    },
    showPicture2(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName2 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic2 = reader.result;
          this.showImage2 = URL.createObjectURL(element);
        };
      }
    },
    showPicture3(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName3 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic3 = reader.result;
          this.showImage3 = URL.createObjectURL(element);
        };
      }
    },
    showPicture4(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName4 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic4 = reader.result;
          this.showImage4 = URL.createObjectURL(element);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    changePic2() {
      document.getElementById("picTure2").click();
    },
    changePic3() {
      document.getElementById("picTure3").click();
    },
    changePic4() {
      document.getElementById("picTure4").click();
    },
    RemoveImage1(index, item) {
      this.pic1 = [];
      this.picture_1 = "";
      this.showImage1 = "";
      this.imageName1 = "";
    },
    RemoveImage2(index, item) {
      this.pic2 = [];
      this.picture_2 = "";
      this.showImage2 = "";
      this.imageName2 = "";
    },
    RemoveImage3(index, item) {
      this.pic3 = [];
      this.picture_3 = "";
      this.showImage3 = "";
      this.imageName3 = "";
    },
    RemoveImage4(index, item) {
      this.pic4 = [];
      this.picture_4 = "";
      this.showImage4 = "";
      this.imageName4 = "";
    },
    cancel() {
      this.$router.push("ManageDealer");
    },
    isValidEmail() {
      const mailformat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (mailformat.test(this.form.email)) {
        this.checkEmail = true;

        this.checkPasswrod();
      } else {
        this.checkEmail = false;

        const Toast = this.$swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          text: "E-mail ไม่ถูกต้อง",
          showConfirmButton: false,
          timer: 1000,
        });
      }
    },

    checkPasswrod() {
      if (this.form.password === this.form.confirmPassword) {
        this.submit();
      } else {
        const Toast = this.$swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          text: "Password ไม่ตรงกัน",
          showConfirmButton: false,
          timer: 1000,
        });
      }
    },
    async submit() {
      // var user = JSON.parse(Decode.decode(localStorage.getItem("DDLotAdmin")));
      // const auth = {
      //   headers: { Authorization: `Bearer ${user.token}` },
      // };
      // console.log("userdata", user);
      const data = {
        ...this.form,
      };
      this.$swal({
        text: "คุณต้องการยืนยันบันทึกข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ยืนยันข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await this.axios.put(
            `${process.env.VUE_APP_API}/priceLists/${this.form.id}`,
            data
            // auth
          );
          console.log(response);

          if (response.data.response_status == "SUCCESS") {
            this.$swal.fire({
              icon: "success",
              text: `แก้ไขข้อมูลสำเร็จ`,
              showConfirmButton: false,
              timer: 1500,
            });
            this.$router.go(-1);
          }
        }
      });
    },
  },
};
</script>
